<template>
  <div>
    <el-dialog
      :title="createEventTitle"
      width="500px"
      :visible="visible"
      center
      :show-close="false"
      @close="close"
      @closed="discard"
      destroy-on-close
    >
      <el-form
        label-width="120px"
        ref="eventForm"
        :model="formData"
        :rules="formDataRules"
        :disabled="isViewOnlyMode"
        v-loading="loading"
      >
        <el-form-item
          :label="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.status')"
          prop="statusType"
          class="mb-0"
        >
          <el-select
            v-model="formData.statusType"
            :placeholder="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.typeWhlen')"
            style="width: 100%"
          >
            <el-option-group key="1">
              <el-option v-for="item in statusTypes" :key="item._id" :value="item._id" :label="item.label">
                <span
                  :style="'float: left; font-size: 20px; margin-top:7px; color:' + item.color"
                  :class="item.icon"
                ></span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.label }}</span>
              </el-option>
            </el-option-group>
            <el-option-group key="2" v-if="canCreateNewStatusTypes">
              <el-option value="new_status">
                <span style="float: left; font-size: 20px; margin-left: 4px; font-weight: bold">+</span>
                <span style="float: right">Neuen Typ hinzufügen</span>
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.starte')"
          prop="dateRange"
          style="width: 100%"
        >
          <pr-date-picker
            :focusDate="dayClicked"
            v-model="formData.dateRange"
            is-range
            :markedDay="dayClicked"
            :disabled="isViewOnlyMode"
          />
        </el-form-item>
        <el-form-item v-if="!formData.noFreeTextAccess" :label="$t('freeText')" prop="freeText" style="width: 100%">
          <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4 }" v-model="formData.freeText" />
        </el-form-item>
        <el-form-item
          v-if="!formData.noFilesAccess && formData.fileList.length"
          :label="$t('src.components.uicomponents.helper.ressourcestatus.statusAnhang')"
          prop="fileList"
          class="mb-0"
          style="width: 100%"
        >
          <div v-if="formData.fileList.length">
            <!-- <el-upload :file-list="formData.fileList" :disabled="true" /> -->
            <div v-for="file in formData.fileList" class="upload-file-item">
              <span>
                <div class="upload-file-item__icon"><attachment-icon /></div>
                <div class="upload-file-item__name">
                  {{ file.name }}
                </div>
              </span>
              <span>
                <div class="upload-file-item__action" v-if="file.url" @click="viewDocument(file)">
                  <i class="el-icon-view"></i>
                </div>
                <div class="upload-file-item__action" @click="downloadFile(file)"><i class="el-icon-download"></i></div>
              </span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer" v-if="initialized && !isViewOnlyMode">
          <cancel-button
            :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.abbrechen')"
            @click="close"
          />
          <save-button
            :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.bernehmen')"
            :loading="loading"
            @click="submitEvent"
          />
        </span>
      </template>
    </el-dialog>
    <status-settings-dialog ref="dialog" @updated="onUpdated" :resourceType="resourceType" />
  </div>
</template>

<script>
import { Form, FormItem, Dialog, Select, Message, DatePicker, Upload, OptionGroup } from "element-ui";
import { moment } from "src/config/moment";
import { sortBy } from "lodash";
import AttachmentIcon from "vue-material-design-icons/Attachment";
import StatusSettingsDialog from "../../UIComponents/Helper/StatusSettingsDialog.vue";
import { mapActions } from "vuex";

export default {
  name: "status-event-create-editor",
  components: {
    AttachmentIcon,
    StatusSettingsDialog,
    [Upload.name]: Upload,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Message.name]: Message,
    [DatePicker.name]: DatePicker,
    [OptionGroup.name]: OptionGroup,
    StatusSettingsDialog,
  },
  props: {
    eventRecord: Object,
    dayClicked: Date,
    getDbEvent: Function,
    resourceId: String,
    resourceType: String,
    resourceRecord: Object,
    eventEditVisible: Boolean,
    ignoreNextUpdateCheck: Function,
    getIsDisabledStatusEvents: Function,
    getCanCreateNewStatusTypes: Function,
  },
  data() {
    return {
      loading: false,
      initialized: false,
      visible: false,
      formDataRules: {
        statusType: {
          required: true,
          message: "Bitte Status auswählen",
          trigger: "change",
        },
        dateRange: {
          required: true,
          message: "Bitte Datum auswählen",
          trigger: "change",
        },
      },
      formData: {
        statusType: "",
        dateRange: null,
        noFreeTextAccess: false,
        noFilesAccess: false,
        freeText: "",
        fileList: [],
      },
      statusTypes: [],
      isViewOnlyMode: false,
      canCreateNewStatusTypes: false,
    };
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    async fetchTypes() {
      try {
        const response = await this.axios.get(`/api/status-events/types?resourceType=${this.resourceType}`);
        this.statusTypes = sortBy(
          response.data.filter((item) => item.active),
          [(o) => o.label.toLowerCase()]
        );
      } catch (error) {
        throw error;
      }
    },
    async initModal() {
      try {
        this.loading = true;
        this.isViewOnlyMode = this.getIsDisabledStatusEvents(this.resourceType);
        await this.fetchTypes();
        this.canCreateNewStatusTypes = this.getCanCreateNewStatusTypes(this.resourceType);
        if (this.eventRecord) {
          this.formData.statusType = this.eventRecord.data.statusEvent._id;
          this.formData.freeText = this.eventRecord.data.freeText;
          this.formData.dateRange = [this.eventRecord.startDate, this.eventRecord.endDate];
          this.formData.noFreeTextAccess = this.eventRecord.data.noFreeTextAccess;
          this.formData.noFilesAccess = this.eventRecord.data.noFilesAccess;
          this.formData.freeText = this.eventRecord.data.freeText;
          this.formData.fileList = this.eventRecord.data.fileList || [];
        }
        this.initialized = true;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    submitEvent() {
      this.$refs.eventForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const formBody = {
              start: moment(this.formData.dateRange[0]).format("YYYY-MM-DD"),
              end: moment(this.formData.dateRange[1]).format("YYYY-MM-DD"),
              statusType: this.formData.statusType,
              freeText: this.formData.freeText,
              resourceId: this.resourceId,
              resourceType: this.resourceType,
              freeText: this.formData.freeText,
            };
            let response;
            this.$props.ignoreNextUpdateCheck();
            if (this.eventRecord) {
              formBody.id = this.eventRecord.id;
              response = await this.axios.put(`/api/status-events/${this.eventRecord.id}`, formBody);
              const event = window.resourceScheduler.eventStore.getById(this.eventRecord.id);
              event.set({
                id: response.data.id,
                dbEventId: response.data.id,
                name: response.data.statusType.label,
                resourceType: response.data.resourceType,
                resourceId: response.data.resourceId,
                startDate: response.data.start,
                start: response.data.start,
                end: response.data.end,
                endDate: response.data.end,
                eventColor: response.data.statusType.color, // is set for status events
                iconCls: response.data.statusType ? response.data.statusType.icon : undefined,
                statusEvent: response.data.statusType,
                draggable: true,
                freeText: response.data.freeText,
                fileList: response.data.fileList,
                noFreeTextAccess: response.data.noFreeTextAccess,
                noFilesAccess: response.data.noFilesAccess,
              });
              this.$emit("eventEdited", response.data);
            } else {
              response = await this.axios.post("/api/status-events", formBody);
              const event = response.data;
              this.$emit("eventAdded", response.data);

              const events = window.resourceScheduler.eventStore.getEventsForResource(event.resourceId);
              window.resourceScheduler.eventStore.remove(events);
              window.resourceScheduler.eventStore.add(
                sortBy(
                  [
                    {
                      id: event.id,
                      dbEventId: event.id,
                      name: event.title,
                      resourceType: event.resourceType,
                      resourceId: event.resourceId,
                      startDate: event.start,
                      start: event.start,
                      end: event.end,
                      endDate: event.end,
                      eventColor: event.statusType.color, // is set for status events
                      iconCls: event.statusType ? event.statusType.icon : undefined,
                      statusEvent: event.statusType,
                      draggable: true,
                      freeText: event.freeText,
                      fileList: event.fileList,
                      noFreeTextAccess: event.noFreeTextAccess,
                      noFilesAccess: event.noFilesAccess,
                    },
                    ...events,
                  ],
                  (o) => new Date(o.startDate).getTime()
                )
              );
            }
          } catch (error) {
            if (error.response && error.response.data) {
              Message.error(error.response.data.message);
            } else {
              Message.error(error.message);
            }
            throw error;
          } finally {
            this.loading = false;
            this.close();
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    discard() {
      this.$refs.eventForm.clearValidate();
      this.formData.statusType = null;
      this.formData.dateRange = null;
      this.formData.freeText = "";
      this.formData.fileList = [];
      this.statusTypes = [];
      this.isViewOnlyMode = false;
      this.canCreateNewStatusTypes = false;
      this.initialized = false;
    },
    viewDocument(file) {
      if (file.url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + file.url;
        this.openPdf({ url: completeUrl, fileName: file.name });
      } else {
        window.open(`${file.url}?view=true`, "_blank");
      }
    },
    downloadFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    async onUpdated(data) {
      try {
        const newStatusTypeId = data._id;
        console.log("onUpdated data", data);
        // check if newly created status type is accessible by current user and pre-select it
        await this.fetchTypes();
        const isAccessible = this.statusTypes.some((item) => item._id === newStatusTypeId);
        if (isAccessible) {
          this.formData.statusType = newStatusTypeId;
        }
      } catch (error) {
        throw error;
      }
    },
  },
  computed: {
    createEventTitle: function () {
      let title = (this.resourceRecord && this.resourceRecord.name) || "";
      if (this.isViewOnlyMode) {
        title += ": Status Übersicht";
      } else {
        title += ": Status zuweisen";
      }
      return title;
    },
  },
  watch: {
    resourceRecord(newVal, oldVal) {
      if (newVal) {
        this.initModal();
        this.visible = true;
      } else {
        this.visible = false;
        this.discard();
      }
    },
    "formData.statusType": function (newVal, oldVal) {
      if (newVal === "new_status") {
        this.formData.statusType = "";
        this.$refs.dialog.$open();
      }
    },
  },
};
</script>

<style></style>
